<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 26"
  >
    <path
      fill="currentColor"
      d="M12 0a3 3 0 0 0-3 3v-.2l-1 6-1.9 5.1-.1.1H5v-1H0v13h5v-3h4c1.6 0 3-.8 4-2 1 1.2 2.4 2 4 2h4v3h5V13h-5v1h-1.1l-2-5.3-1-5.9.1.2a3 3 0 0 0-3-3c-.3 0-.7 0-1 .2L12 0Zm0 2v16.4A3.1 3.1 0 0 1 9 21H5v-5h1a2.2 2.2 0 0 0 1.9-1.1l2-5.6L11 3c0-.5.4-1 1-1Zm2 0c.6 0 1 .4 1 1l1 6.3 2 5.5h.1A2.2 2.2 0 0 0 20 16h1v5h-4a3.1 3.1 0 0 1-3-2.6V2ZM2 15h1v9H2v-9Zm21 0h1v9h-1v-9Z"
    />
  </svg>
</template>
